
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { SnackbarAction, SnackbarKey, useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { StringIfPlural, useTranslation  } from 'react-i18next';

//import colorNameList from 'color-name-list';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';


import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import PlaylistAddCircleIcon from '@mui/icons-material/PlaylistAddCircle';
import QueueIcon from '@mui/icons-material/Queue';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

import BackpackIcon from '@mui/icons-material/Backpack';

import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import { MdOutlineAdd } from 'react-icons/md';

import { ChromePicker, ColorResult } from 'react-color';



import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom, isSaveLoadingAtom, currentUserSessionAtom } from 'library/store';
import useContentService, { useBasicFilterContent } from './services/Content';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';


import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, MenuItem, Tabs, Tab, Typography, Chip, Popover, useTheme } from '@mui/material';
import NumberFormat from 'react-number-format';

import { IContent, defaultContent } from './models/Content';


import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm, defaultBasicTextFilterProps } from 'components/ui/BasicTextFilterForm';

import useEnumerationService, { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';

//import useDeliveryService from 'features/configuration/services/Delivery';

import useUtils from 'library/utils';

import IEnumeration, { Enum_LINE_OF_BUSINESS, Enum_SERVICE_TASK, Enum_FORMATION_BILLING_TYPE, Enum_ARTICLE_OPTION_CLASS, 
      Enum_MAINTENANCE_CATEGORY_CLASS, Enum_CONTRACT_SCOPE_CLASS, Enum_PERSON_SERVICE ,IEnumerationItem, Enum_ARTICLE_WRAPPING } from 'features/configuration/models/Enumeration';

//import {ISharing} from 'features/setup/models/Sharing';
//import useSharingService, { useBasicFilterSharing } from 'features/setup/services/Sharing';

import { IExtension, IExtensionType, defaultExtension  } from 'features/configuration/models/ExtensionType';
import useExtensionTypeService, { useBasicFilterExtensionType } from 'features/configuration/services/ExtensionType';

import { justifyCenter, typographyGroupBoxStyling, carouselImage } from 'themes/commonStyles';

import EntityExpression from 'components/ui/EntityExpression';
import { isFalsy } from 'utility-types';
import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import { debounce, sum } from 'lodash';
import { GrClose, GrSearch } from 'react-icons/gr';
import EnhancedTable from 'components/ui/EnhancedTable';
import { ExtensionFormDialog } from 'components/ExtensionFormDialog';
import ExtensionValueField from 'components/ui/ExtensionValueField';
import { IContentType, defaultContentType } from 'features/setup/models/ContentType';
import { useBasicFilterContentType } from 'features/setup/services/ContentType';
import { useBasicFilterExternalProduct, useBasicFilterProduct } from 'features/setup/services/Product';
import { IProduct } from 'features/setup/models/Product';
//import { IArticleStock } from 'features/configuration/models/Delivery';


export const ContentForm: FC<IContent> = (props: IContent = defaultContent) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const theme = useTheme();

  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const { applicationSetup} = useRecoilValue(currentUserSessionAtom);

  const {retrieveEntity, retrieveData, openEntityActionDrawer, 
      checkEntityExpressionSyntax, checkEntitySaveAuthorization } = entityService();

  const {defaultValue, valueString} = useExtensionTypeService();
  const {getEnumerationItemsByEnumerationCodes, getAsOptions } = useEnumerationService();

  //const {getArticlePerStores } = useDeliveryService();

  const { createContent, updateContent, getContentsByContentType } = useContentService();

  const {capitalizeFirstLetter, range} = useUtils();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  //const colors = colorNameList.filter(c => c.name.split(' ').length === 1 && c.name.length <= 5).map((color) => color);
  
  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterContent = useBasicFilterContent( 0,
    (event: React.MouseEvent<unknown>, row: IContent) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  const emptyFunc = (obj: any) => {}

  const [openContentTypeFilter, setOpenContentTypeFilter] = useState(false);
  const basicFilterContentType = useBasicFilterContentType( 
      async (event: React.MouseEvent<unknown>, row: IContentType) => {
          const {id, name, description} = row;
          
          setValue('contentTypeId', id);
          setValue('contentTypeName', name);
          setValue('contentTypeDescription', description);
                
          setOpenContentTypeFilter(false);
      }
  );

  const [openEnumerationFilter, setOpenEnumerationFilter] = useState(false);
  const basicFilterEnumeration = useBasicFilterEnumeration( 
      (event: React.MouseEvent<unknown>, row: IEnumeration) => {
          const {id, name, description} = row;

          // setValue('enumerationId', id);
          // setValue('enumerationName', name);
                           
          setOpenEnumerationFilter(false);
      }
  );

  const [openProductFilter, setOpenProductFilter] = useState(false);
  const basicFilterProduct = useBasicFilterProduct( 0,
      (event: React.MouseEvent<unknown>, row: IProduct) => {
          const {id, name, description} = row;

          setValue('productId', id);
          setValue('productName', name);
                           
          setOpenProductFilter(false);
      }
  );

  const [openExternalProductFilter, setOpenExternalProductFilter] = useState(false);
  const basicFilterExternalProduct = useBasicFilterExternalProduct(
      (event: React.MouseEvent<unknown>, row: IProduct) => {
          const {id, name, description} = row;

          setValue('externalProductId', id);
          setValue('externalProductName', name);
                           
          setOpenExternalProductFilter(false);
      }
  );

  const refParentContentTypeId = useRef<number>(0);    
  const [openParentContentFilter, setOpenParentContentFilter] = useState(false);
  const basicFilterParentContent = useBasicFilterContent( refParentContentTypeId.current,
    (event: React.MouseEvent<unknown>, row: IContent) => {
      const {id} = row;
      setValue('parentContentId', id);
      setOpenParentContentFilter(false);
      
      }
  );
  

  const methods = useForm<IContent>({defaultValues: {...defaultContent, }});

  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  // const watchBase64File = watch('base64File');
  // const watchFileName = watch('fileName');
  
  // const watchName = watch('name');    
  // const watchDescription = watch('description');    
  const watchContentTypeId = watch('contentTypeId');    
  const watchParentContentId = watch('parentContentId');
  
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [color, setColor] = useState<string>('#000');

  // const watchParentContentId = watch('parentContentId');

  // let { fields, append: appendCoverages, update: updateCoverage ,remove: removeCoverage,  } = useFieldArray({//<O, TName>({ //<O,`billingDetails.${number}.billingDetailTasks`>({
  //   name: `productCoverages`,
  //   control,            
  // });
  

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IContent>,Error,IContent>(
      _id>0?updateContent:createContent, {   
        onSuccess: (data: IResult<IContent>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          setCurrentFormNameAtom(`${t('Content')} - # ${data.data.id} # ` );
          //queryClient.invalidateQueries(['Content',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

    const {data: _data, refetch} = useQuery<IContent>(['Content', _id], () => retrieveEntity('Content',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );


    const {data: parentContents} = useQuery<IContent[]>(['ParentContents', refParentContentTypeId.current], () => getContentsByContentType(refParentContentTypeId.current), 
      {refetchOnWindowFocus: false ,enabled: refParentContentTypeId.current>0 } );

      // const {data: enumItems} = useQuery<IEnumerationItem[]>(
      //   ['EnumerationItems', 'Content'], () => 
      //     getEnumerationItemsByEnumerationCodes( [Enum_LINE_OF_BUSINESS, 
      //         Enum_SERVICE_TASK, Enum_FORMATION_BILLING_TYPE, Enum_ARTICLE_OPTION_CLASS, Enum_ARTICLE_WRAPPING,
      //         Enum_MAINTENANCE_CATEGORY_CLASS, Enum_CONTRACT_SCOPE_CLASS, Enum_PERSON_SERVICE ] ));
 
  

    const refEnumItems = useRef<IEnumerationItem[]>();    
    // useEffect( () => {   
    //     refEnumItems.current = enumItems;
      
    // }, [enumItems])

    // const [articleStocks, setArticleStocks] = useState<IArticleStock[]>([]);
    // useEffect( () => {
      
    //   async function _() {   
    //     if(_data && _data.type === 'article' && _data.id > 0) {         
    //       const stocks = await getArticlePerStores( 0, _id);
    //       setArticleStocks(stocks);
    //     }
    //   }
    //   _();  
    // }, [_data] );

    const handleClickOpenProduct = (event: any) => {        
      setOpenProductFilter(true);
    }

    const handleClickOpenExternalProduct = (event: any) => {        
      setOpenExternalProductFilter(true);
    }

    const handleClickOpenParentContent = (event: any) => {        
      setOpenParentContentFilter(true);
    }
    const handleClickOpenContentType = async (event: any) => {   
      setOpenContentTypeFilter(true);
    }

    const handleClickEditContent = (event: any, contentId: number) => {      
      _setId(contentId);
    }

    const handleClickParentContent = (event: any, contentId: number) => {   
      setValue('parentContentId', contentId);   
      setOpenParentContentFilter(false);
    }

    const [currentContentType, setCurrentContentType] = useState<IContentType>(defaultContentType);

    const [contents, setContents] = useState<IContent[]>([]);
    useEffect( () => {              
      async function _() {        
        if(watchContentTypeId > 0 && currentContentType.id !== watchContentTypeId) {
          
          const contentType = await retrieveEntity('ContentType', watchContentTypeId) as IContentType;
          setCurrentContentType(contentType);
          refParentContentTypeId.current = contentType.parentContentTypeId;

          resetContent({...defaultContent, parentContentDef: getParentContentDef()}, contentType);                
        }    
        const arrContens = await getContentsByContentType(watchContentTypeId);   
        setContents(arrContens); 
      }  
      
      _();
    }, [ watchContentTypeId, _data, _id]); 



    const getParentContentDef = () : string => {
      const parentContent = parentContents?.find(x => x.id === watchParentContentId); 
      if(isFalsy(parentContent)) return '';

      const {contentExtensions} = parentContent!;
      return contentExtensions.filter(x => x.isMainField).map(x => `${valueString(x)} `).join(' <-> '); 
    }

    useEffect( () => {     
       setValue('parentContentDef', getParentContentDef()); 
    }, [ watchParentContentId, _id,]); 


    const resetContent = (content: IContent, contentType: IContentType) => {

      _setId(content.id);
      const contentExtensions = contentType.extensions.map( x => { 

        const ext = content.contentExtensions.find(y => y.alias === x.alias && y.extensionTypeType === x.extensionTypeType);
        return {...x, id: ext?.id ?? 0, 
          extensionId: x.id, value: ext?.value ?? defaultValue(x.extensionTypeType) , 
                  descValue: ext?.descValue ?? ''
        } 
      });

      const {id, name, description} = contentType;
      reset({...content, contentTypeId: id, contentTypeName: name, contentTypeDescription: description, contentExtensions});
    }

      useEffect( () => {        
        setCurrentFormNameAtom(t('Content'));
        //setCurrentBasicTextFilterProps(basicFilterContent);
        setCurrentBasicTextFilterProps(defaultBasicTextFilterProps);
      }, []);    
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
            // setCurrentFormName(t('Billing'));        
            setCurrentFormNameAtom(_id>0?`${t('Content')} - # ${_id} # -`: t('Content') );
            if(_id > 0)
              retrieveData('Content',_id, refetch);  
          }, [_id] );
    
    
        useEffect( () => {
            
        if(_data && _data.id > 0) {          
            //reset(_data);
            
            resetContent({..._data, parentContentDef: getParentContentDef()}, currentContentType);
        }
        }, [_data]);
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
        // _setId(0);      
        // reset({...defaultContent });    
        // setContents([]);
        if(currentContentType.id <= 0)
          setOpenContentTypeFilter(true);
        else {
          resetContent({...defaultContent}, currentContentType);
        }
          
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {      
        if(!checkEntitySaveAuthorization('Content', _id)) {
          setIsSaveLoading(false);
             return;
        }          
  
        const data = getValues(); 
        
        // if(data.name.trim() === '' || data.description.trim() === '') {
        //     enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
        //       anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
        //     setIsSaveLoading(false);
        //     return;
        //   }

        // const taskCodes = data.serviceTasks.map(x => x.taskCode);
        // if( Array.from(new Set(taskCodes)).length !== data.serviceTasks.length) {
        //   enqueueSnackbar( t('Tasks are not well defined'), { variant: 'warning',
        //     anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
        //   setIsSaveLoading(false);
        //   return;
        // }

           
        mutate(data);
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('Content', _id);
      }

    
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);        
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={0.5} columnSpacing={0.1}>
                    <Grid item xs={12} md={7} lg={7} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                          
                          <Box sx={{ mt: 1, width: '100%' }} >
                              <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                              <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                              <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                              <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />
                              
                              <TextField sx={{width:'calc(40% - 8px)'}} id="name" label={t('Name')} {...register('contentTypeName')}
                                inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } autoFocus  
                                InputProps={{
                                  readOnly: true,
                                  endAdornment: (
                                    <InputAdornment position="end">                                            
                                      <IconButton color="primary" onClick={handleClickOpenContentType}>
                                        <ArrowDropDownCircleIcon />
                                      </IconButton>                                                                                               
                                  </InputAdornment>
                                )
                              }} />
                              <TextField sx={{width:'calc(60% - 8px)'}} id="description" label={t('Description')} {...register('contentTypeDescription')} />                                                     
                              { openContentTypeFilter && <FormDialog open={openContentTypeFilter} maxWidth='md' 
                                okText={t('OK')} cancelText='' title={`${t('ContentType')}...`} onCancel={()=> {}} 
                                onClose={()=> {setOpenContentTypeFilter(false);}} onOk={()=> {setOpenContentTypeFilter(false);}}  >
                                    <BasicTextFilterForm<IContentType> {...basicFilterContentType } />
                            </FormDialog> }     
                            

                                                                         
                          </Box>   
                          <Box sx={{ mt: 1, width: '100%' }} > 
                            <Typography  variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                              {` ${t(('Definition of content'))}`}
                            </Typography>                                                       
                          </Box> 
                          <Box sx={{ mt: 1, width: '100%' }} >
                            <FormControlLabel sx={{width:'calc(20% - 8px)'}}
                              label={`${t('Active ?')}`}
                              control={
                                <Controller
                                  name={`isActive`}
                                  control={control}
                                  render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                />} />
                              <Controller                                
                                render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat      
                                      //decimalScale={2}
                                      label={t('Display order')} sx={{width:'calc(15% - 8px)'}}
                                      allowEmptyFormatting={false}
                                      control={control}             
                                                   
                                      //fixedDecimalScale={true} 
                                      thousandSeparator={true}
                                      onValueChange={ (v) => onChange(v.floatValue) }
                                      defaultValue={value}
                                      value={value}
                                      //{...field}
                                      customInput={TextFieldRight}
                                      //customInput={(props) => <TextField {...props} sx={{width:'calc(20% - 8px)'}} id="roleName" inputProps={{style: { textAlign: 'right' }}} />}
                                    />
                                  );
                                }}
                                name={`displayOrder`}                                
                                control={control}
                              />
                              {(currentContentType.isProduct && !currentContentType.isExternalProduct) && <TextField sx={{width:'calc(32% - 8px)'}} id="productName" label={`${t('Product')}`}
                                  {...register('productName')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">                                            
                                        <IconButton color="primary" onClick={handleClickOpenProduct}>
                                          <ArrowDropDownCircleIcon />
                                        </IconButton>                                                                                               
                                    </InputAdornment>
                                  ) 
                                }}
                              /> }
                              {(currentContentType.isProduct && currentContentType.isExternalProduct) && <TextField sx={{width:'calc(32% - 8px)'}} id="externalProductName" label={`${t('External product')}`}
                                  {...register('externalProductName')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">                                            
                                        <IconButton color="primary" onClick={handleClickOpenExternalProduct}>
                                          <ArrowDropDownCircleIcon />
                                        </IconButton>                                                                                               
                                    </InputAdornment>
                                  ) 
                                }}
                              /> }
                              { (!isFalsy(currentContentType.parentContentTypeId) && currentContentType.parentContentTypeId > 0) &&  
                                 <TextField sx={{width:'calc(33% - 8px)'}} id="parentContentDef" label={`${t('Content')} - ${t('Parent')}`}
                                  {...register('parentContentDef')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                  helperText={`Id : ${getValues().parentContentId}`}
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">                                            
                                        <IconButton color="primary" onClick={handleClickOpenParentContent}>
                                          <ArrowDropDownCircleIcon />
                                        </IconButton>                                                                                               
                                    </InputAdornment>
                                  ) 
                                }}
                              /> }
                              { openProductFilter && <FormDialog open={openProductFilter} maxWidth='md' 
                                okText={t('OK')} cancelText='' title={`${t('Product')}...`} onCancel={()=> {}} 
                                onClose={()=> {setOpenProductFilter(false);}} onOk={()=> {setOpenProductFilter(false);}}  >
                                    <BasicTextFilterForm<IProduct> {...basicFilterProduct } />
                            </FormDialog> }   

                            { openExternalProductFilter && <FormDialog open={openExternalProductFilter} maxWidth='md' 
                                okText={t('OK')} cancelText='' title={`${t('External product')}...`} onCancel={()=> {}} 
                                onClose={()=> {setOpenExternalProductFilter(false);}} onOk={()=> {setOpenExternalProductFilter(false);}}  >
                                    <BasicTextFilterForm<IProduct> {...basicFilterExternalProduct } />
                            </FormDialog> }   

                            { openParentContentFilter && <FormDialog open={openParentContentFilter} maxWidth='sm' height='60vh'
                                okText='' cancelText='' title={`${t('Content')}... ${t('Parent')}(${currentContentType.name})`} onCancel={()=> {}} 
                                onClose={()=> {setOpenParentContentFilter(false);}} onOk={()=> {setOpenParentContentFilter(false);}}  >
                                    <Stack flexDirection='column'> 
                                      <Box sx={{ mt: 1, width: '100%' }} >
                                        {                          
                                            (parentContents || []).map( ( {id, isActive, contentExtensions} ,idx) => 
                                              ( <Chip 
                                                  sx={{ ml: 1, mr:1, mb: 1, 
                                                        backgroundColor: isActive? applicationSetup.activeContentColor : applicationSetup.inactiveContentColor,
                                                        color: theme.palette.getContrastText(isActive? applicationSetup.activeContentColor : applicationSetup.inactiveContentColor) }}
                                                  key={`${idx} - ${id}`} 
                                                  label={ contentExtensions.filter(x => x.isMainField).map(x => `${x.alias} : ${valueString(x)} `).join(' <-> ') } 
                                                  variant="outlined"
                                                  //onDelete={() => {removeExtension(idx)}} 
                                                  onClick={(event) => handleClickParentContent(event, id)}
                                                  />
                                                  ))
                                          }
                                      </Box>
                                    </Stack>
                            </FormDialog> }                        
                          </Box>                   
                          {  range(0, (getValues().contentExtensions.length+1)/2).map( (ix) => {
                                      
                                       return (
                                        <Box sx={{ mt: 1, width: '100%' }} key={` box-i- ${ix}`}> 
                                          {range(0, Math.min(2, getValues().contentExtensions.length-2*ix) ).map( (iy) => { // Math.max(getValues().policyExtensions.length, 3*(ix+1))
                                            const index = 2*ix + iy;
                                            const contentExtension = getValues().contentExtensions[index];
                                            
                                            return (
                                              <ExtensionValueField<IContent> key={`ext val ${contentExtension.alias}`}
                                                  control={control} setValue={setValue}
                                                  name={`contentExtensions.${index}.value`}
                                                  descName={`contentExtensions.${index}.descValue`}
                                                  textLength={contentExtension.extensionTypeTextLength}
                                                  formattedText={contentExtension.extensionTypeFormattedText}
                                                  label={contentExtension.description}
                                                  type={contentExtension.extensionTypeType}
                                                  options={(contentExtension.extensionType.enumeration?.enumerationItems || []).map
                                                      (e => ({value: e.code, name: e.name}))} // policyExtension.enumerationExOptions
                                                  width='49'
                                              /> )
                                              } )
                                            }                                                    
                                        </Box>)
                                    }) }                           
                        </Stack>                        
                      </Grid>
                    <Grid item xs={12} md={5} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                      <Stack flexDirection='column'>      
                        <Box sx={{ mt: 1, width: '100%' }} > 
                          <Typography  variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                            {` ${t(('List of items'))}`}
                          </Typography>                                                       
                        </Box> 
                        <Box sx={{ mt: 1, width: '100%' }} >
                          {                          
                              contents.map( ( {id, isActive, contentExtensions} ,idx) => 
                                ( <Chip 
                                    sx={{ ml: 1, mr:1, mb: 1, 
                                          backgroundColor: isActive? applicationSetup.activeContentColor : applicationSetup.inactiveContentColor,
                                          color: theme.palette.getContrastText(isActive? applicationSetup.activeContentColor : applicationSetup.inactiveContentColor) }}
                                    key={`${idx} - ${id}`} 
                                    label={ contentExtensions.filter(x => x.isMainField).map(x => `${x.alias} : ${valueString(x)} `).join(' <-> ') } 
                                    variant="outlined"
                                    //onDelete={() => {removeExtension(idx)}} 
                                    onClick={(event) => handleClickEditContent(event, id)}
                                    />
                                    ))
                            }
                        </Box>                
                        
                      </Stack>
                    </Grid>                       
                </Grid>
            </Box>
        </FormProvider> 
  )
}

